import { apiClient } from '@api/client';
import { nonNull } from '@helpers/non-null';
import { ErrorPage404, ErrorPage500 } from '@pages/ErrorPage';
import { useMutation } from '@tanstack/react-query';
import React, { ReactElement, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface AsanaOAuthInstallProps {
  loader: ReactElement;
}

export default function AsanaOAuthInstall({ loader }: AsanaOAuthInstallProps) {
  const [searchParams] = useSearchParams();
  const asanaWorkspaceId = searchParams.get('domainId');
  const { mutate, isError } = useMutation({
    mutationFn: async () => {
      const {
        data: { url }
      } = await apiClient.asana.asanaControllerInstall({
        asanaWorkspaceId: nonNull(asanaWorkspaceId)
      });
      window.location.href = url;
    }
  });
  useEffect(() => {
    if (asanaWorkspaceId) mutate();
  }, [asanaWorkspaceId, mutate]);
  if (!asanaWorkspaceId) {
    return <ErrorPage404 />;
  }
  if (isError) {
    return <ErrorPage500 />;
  }
  return loader;
}
